.App {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: green;
  font-size: 50px;
}
